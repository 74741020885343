import { useState, useEffect, useCallback } from 'react'
import { navigate } from 'gatsby'

const useForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [values, setValues] = useState({})
  const [success, setSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitting(true)
    const data = new FormData(e.target)
    const newValues = {}
    for (var pair of data.entries()) {
      newValues[pair[0]] = pair[1]
    }
    setValues(newValues)
    setSubmitted(true)
  }

  // const encode = (data) => {  
  //   return Object.keys(data)
  //     .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
  //     .join("&");
  // }
  const encode = (data) => {  
    return Object.keys(data)
      //.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .map(key => {
        var keyName = key;
  
        //map fields names to Zoho's Lead field names
        if(key === 'firstName') { keyName = "First Name" } 
        else if(key === 'lastName') { keyName = "Last Name" }
        else if(key === 'leadSource') { keyName = "Lead Source" }
        else if(key === 'brand') { keyName = "LEADCF4" }
        
        return encodeURIComponent(keyName) + "=" + encodeURIComponent(data[key])
        
      })
      .join("&");
  }

  const sendValues = useCallback(() => {
    /**
     * Code for submitting form values
     * via an external API goes here.
     */
    // setTimeout(function() {
    //   console.log(values)
    //   setSuccess(true)
    //   setSubmitting(false)
    // }, 1000)

        
    // TEST (to view posts: https://requestbin.com/r/en1rtz84oxwh8/1lNiUjbR8eIBY7IQeGhXC0yBXvF)      
    // const url = "https://en1rtz84oxwh8.x.pipedream.net/"

    // LIVE Zoho
    const url = "https://crm.zoho.com/crm/WebToLeadForm"
    
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "web-lead", ...values })
    })
    .then(() => {
      //resetForm()
      //console.log(values)
      navigate("/thank-you/")
    })
    .catch(() => {
      alert('Error');
    })
    .finally(() => {
      setSuccess(true)
      setSubmitting(false)
    })
  }, [values])

  useEffect(() => {
    if (submitted) {
      sendValues()
    }
  }, [submitted, sendValues])

  return {
    handleSubmit,
    submitting,
    submitted,
    success
  }
}

export default useForm
