import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'
import useSiteMetadata from '@helpers/useSiteMetadata'

const Commitment = props => {
  const { topic } = useSiteMetadata()

  return (
    <Section aside title='Contact Us' {...props}>
      <Card variant='paper'>
        <Text variant='p'>
          Buying the right {topic} Franchise isn't easy, but we can help.
        </Text>
        <Text variant='p'>
          Wherever you are in your journey to becoming a {topic} Franchise owner, 
          please do not hesitate to contact us.
        </Text>
      </Card>
    </Section>
  )
}

export default Commitment
