import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
// import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import useSiteMetadata from '@helpers/useSiteMetadata'

export default () => {
  const { topic } = useSiteMetadata()

  return (  
    <>
      <Seo title='Contact' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header={`Request ${topic} Franchise Information`}
            subheader={`Take the first step toward buying a ${topic} Franchise`}
          />
          <Divider />
          <ContactForm />
        </Main>
        <Sidebar>
          <Commitment />
          <Divider />
          {/* <ContactInfo /> */}
        </Sidebar>
      </Stack>
    </>
  )
}